import React from 'react';
import Layout from '../../components/Layout';
import Seo from '../../components/Seo';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import withTranslations from '../../components/hoc/withTranslations';
import { useState } from 'react';
import CheckIcon from '../../../assets/icons/check.svg';
import BookIcon from '../../../assets/icons/book.svg';
import CalendarIcon from '../../../assets/icons/calendar.svg';
import SitDownManIcon from '../../../assets/icons/sit-down-man.svg';
import HTMLIcon from '../../../assets/images/courses/react/HTML.svg';
import CSSIcon from '../../../assets/images/courses/react/CSS.svg';
import ReactIcon from '../../../assets/images/courses/react/ReactImage.svg';
import APIIcon from '../../../assets/images/courses/react/API.svg';

const ReactCourse = () => {
  const [selected, setSelected] = useState(0);

  let images = useStaticQuery(graphql`
    query {
      zoom: file(relativePath: { eq: "courses/react/zoom.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  console.log(images);

  const learnings = [
    {
      text: 'Definir la estructura de una página web con HTML',
      icon: HTMLIcon
    },
    {
      text: 'Dar estilos a una aplicación con CSS y crear UI responsivas',
      icon: CSSIcon
    },
    {
      text: 'Crear una Single Page Application con ReactJS',
      icon: ReactIcon
    },
    {
      text: 'Consumir una API RESTful para interactuar con servicios web',
      icon: APIIcon
    }
  ];

  return (
    <Layout manualMargin manualPadding>
      <Seo
        title='Webapps modernas con React'
        description={
          'Aprende a desarrollar sitios y aplicaciones web con las mismas tecnologías que utiliza Facebook, Apple y Amazon.'
        }
        keywords={[
          `git`,
          `css`,
          `html`,
          `web`,
          `javascript`,
          `react`,
          `reactjs`,
          `curso`,
          `taller`,
          `paginas`,
          `desarrollo`,
          `workshop`,
          `frontend`,
          `development`,
          `aplicacion`,
          `app`
        ]}
      />

      <div className='header max-w-5xl mx-auto px-4 mt-20'>
        <h1 className=''>
          Webapps modernas con <div className='text-react-blue'>React</div>
        </h1>
        <p>
          Aprende a desarrollar sitios y aplicaciones web con las mismas tecnologías que utiliza Facebook, Apple y
          Amazon.
        </p>

        <div style={{ maxWidth: 300 }}>
          <Link className='bg-react-blue global-primary-button' to='/courses/react/sign-up'>
            Inscríbete ahora
          </Link>
        </div>
      </div>

      <section className='global-section md:max-w-5xl md:mx-auto md:px-4 md:mt-20'>
        <h3 className='px-4'>Qué aprenderás</h3>
        <div className='what-youll-learn flex flex-col md:flex-row'>
          <div className='learnings flex space-x-4 md:space-y-4 md:space-x-4 overflow-x-auto md:flex-col p-1'>
            <div className='w-6 flex-none'></div>
            {learnings.map((l, i) => (
              <Learning key={i} text={l.text} onHover={() => setSelected(i)} selected={i === selected} />
            ))}
            <div className='w-6 flex-none'></div>
          </div>

          <div
            className='image justify-center items-center flex flex-col flex-none relative w-full md:flex-1'
            style={{ height: 500 }}
          >
            {learnings.map((l, i) => {
              const Icon = l.icon;
              return (
                <div
                  key={i}
                  className='icon'
                  style={{
                    position: 'absolute',
                    width: '100%',
                    transition: 'opacity 0.4s ease-in-out',
                    opacity: selected === i ? '1' : '0'
                  }}
                >
                  <Icon width='100%' />
                </div>
              );
            })}
          </div>
        </div>
      </section>

      <section className='forthe global-light global-section shadow-inner'>
        <div className='py-10 max-w-5xl mx-auto px-4 flex flex-col'>
          <div className='flex justify-center'>
            <div className='bg-gray-100 flex items-center justify-center p-3 font-semibold text-lg rounded-lg space-x-2 text-gray-800'>
              <BookIcon width='1.8em' />
              <span>Positive Mentorships</span>
            </div>
          </div>

          <div className='intro text-center flex flex-col items-center max-w-3xl mx-auto py-6'>
            <h3 className='text-gray-700'>Para los autodidactas, para los disruptivos</h3>
            <p>
              Queremos compartir nuestros conocimientos y habilidades, sin embargo, algo que nos distingue y que
              nosotros creemos necesario para el crecimiento profesional es el autodidactismo y las habilidades de
              investigación. Por esta razón, se creó Positive Mentorships.
            </p>
            <SitDownManIcon />
          </div>

          <div className='mb-8'>
            <div className='notforall flex items-center flex-col md:flex-row md:space-x-3'>
              <span className='text-3xl font-bold flex-1'>Estos cursos no son para todos</span>
              <p className='flex-2'>
                Este curso es sólo para los autodidactas, personas que no se rinden y que tienen ganas de cambiar su
                entorno de manera positiva.
              </p>
            </div>

            <p>
              En esta modalidad de cursos el estudiante tiene que dedicar un promedio de 3 horas diarias de
              investigación. Nosotros ponemos la ruta predefinida y establecemos de dónde se debe de obtener la
              información; el estudiante debe de comprometerse a seguir la ruta en el tiempo señalado.
            </p>
          </div>

          <div className='notforall flex items-center flex-col md:flex-row md:space-x-3'>
            <span className='text-3xl font-bold flex-1'>Gana dinero por aprender</span>
            <p className='flex-2'>
              La persona que acumule más puntos en las competencias evaluativas obtendrá una remuneración económica.
            </p>
          </div>

          <div className='prizes flex flex-col md:flex-row space-y-5 md:space-y-5 justify-center my-10'>
            <div className='prize flex-1 flex flex-col items-center justify-center'>
              <span className='font-bold text-green-700 text-2xl'>$1,000.00 MXN</span>
              <span className='text-lg'>1º lugar</span>
            </div>

            <div className='prize flex-1 flex flex-col items-center justify-center'>
              <span className='font-bold text-green-700 text-2xl'>$600.00 MXN</span>
              <span className='text-lg'>2º lugar</span>
            </div>

            <div className='prize flex-1 flex flex-col items-center justify-center'>
              <span className='font-bold text-green-700 text-2xl'>$450.00 MXN</span>
              <span className='text-lg'>3º lugar</span>
            </div>
          </div>
        </div>
      </section>

      <section className='requirements global-section max-w-5xl mx-auto px-4 mt-20 items-center '>
        <h3>Requerimientos</h3>
        <div className='checks flex justify-center flex-col md:flex-row max-w-4xl mx-auto md:space-x-5 '>
          <div className='left flex-1'>
            <Requirement text={'Saber programación básica'} />
            <Requirement text={'Una computadora'} />
            <Requirement text={'Conexión a Internet'} />
          </div>
          <div className='right flex-1'>
            <Requirement text={'Editor de código'} />
            <Requirement text={'Opcional: cámara y micrófono'} />
          </div>
        </div>
      </section>

      <section className='global-section max-w-3xl mx-auto px-4 space-y-5'>
        <div className='calendar flex flex-col md:flex-row'>
          <div className='icon flex-1 p-4 flex'>
            <CalendarIcon width='200px' />
          </div>

          <div className='info flex-3 md:ml-5 p-4 gap-5 grid grid-cols-1 md:grid-cols-2'>
            <div className='flex flex-col justify-center'>
              <span className='text-lg'>Fecha de inicio</span>
              <span className='font-bold text-xl'>5 de octubre</span>
            </div>
            <div className='flex flex-col justify-center'>
              <span className='text-lg'>Días</span>
              <span className='font-bold text-xl'>Lunes a Viernes</span>
            </div>
            <div className='flex flex-col justify-center'>
              <span className='text-lg'>Fecha de finalización</span>
              <span className='font-bold text-xl'>16 de octubre</span>
            </div>
            <div className='flex flex-col justify-center'>
              <span className='text-lg'>Horario</span>
              <span className='font-bold text-xl'>4:00 - 5:00 P.M.</span>
            </div>
          </div>
        </div>

        <div className='calendar flex flex-col md:flex-row'>
          <div className='img flex flex-1 p-4'>
            <Img
              fluid={images.zoom.childImageSharp.fluid}
              style={{ height: 200, width: 200 }}
              imgStyle={{ objectFit: 'contain' }}
            />
          </div>
          <div className='info flex justify-center flex-col p-4 flex-3 md:ml-5'>
            <span className='font-bold text-xl'>Modalidad online</span>
            <p>
              Las sesiones serán por videoconferencia utilizando la plataforma Zoom. No será obligatorio activar la
              cámara ni el micrófono.
            </p>
          </div>
        </div>

        <div className='price my-6 max-w-3xl mx-auto px-4 text-center flex flex-col'>
          <span className='font-bold text-5xl'>$389.00 MXN</span>
          <span className='text-3xl'>Único pago</span>
        </div>
      </section>

      <section className='action max-w-3xl mx-auto px-4 flex flex-col items-center text-center'>
        <h3>Comienza a desarrollar apps modernas ahora</h3>
        <div style={{ maxWidth: 300 }}>
          <Link className='bg-react-blue global-primary-button' to='/courses/react/sign-up'>
            Inscríbete
          </Link>
        </div>
      </section>
    </Layout>
  );
};

const Requirement = ({ text }) => (
  <div className='flex space-x-3 items-center flex-1'>
    <div className='icon flex-none'>
      <CheckIcon />
    </div>
    <span className='text-lg'>{text}</span>
  </div>
);

const Learning = ({ text, selected, onHover }) => (
  <div
    className={
      'p-4 shadow-md rounded-md text-lg w-56 flex-none items-center justify-center flex  transition-colors duration-200 cursor-default ' +
      (selected && 'bg-gray-900')
    }
    style={{ backgroundColor: '#4B4B4B' }}
    onMouseEnter={onHover}
  >
    {text}
  </div>
);

export default withTranslations()(ReactCourse);
